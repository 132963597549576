import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, Button } from "antd";
import axios from "axios";

export default function UpdateProfileModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, []);

  const handleUpdateProfile = (values) => {
    setLoading(true);
    axios
      .post("https://backend.pxg6404.uta.cloud/api/userupdate/updateprofile", {
        id: user.id,
        ...values,
      })
      .then((response) => {
        const updatedUser = { ...user, ...values };
        sessionStorage.setItem("user", JSON.stringify(updatedUser));
        message.success(response.data.message);
        onClose();
      })
      .catch((err) => {
        console.error(err);
        message.error("Failed to update profile");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title="Update Profile"
      visible={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form onFinish={handleUpdateProfile} initialValues={user}>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please enter your username" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mobile Number"
          name="mobile_number"
          rules={[
            { required: true, message: "Please enter your mobile number" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name="dob"
          rules={[
            { required: true, message: "Please enter your date of birth" },
          ]}
        >
          <Input type="date" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Updating..." : "Update Profile"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
