import React from 'react'
import Header from './header'
import { Card, Space, Typography,Row, Col } from 'antd';
export default function Services() {
  const { Title } = Typography;
  return (
  <><Header headerContent={<div className="content">
  <h1>Security Manager</h1>
</div>} />
<Row justify="space-between" align="middle">
  <Col span={4} xs={24} md={12} lg={4}>
<Space direction="vertical" size={16}>
    <Card title={<Title level={3}>Manage Buildings</Title>} >
    <div>
        <img src="building.jpeg" alt="Avatar"></img>
        <div className="card-container">
          <p>Managing buildings in a community involves a range of tasks that ensure the efficient and effective operation
            of the buildings, while also meeting the needs and expectations of the residents and other stakeholders
            ,Maintenance,safety & security etc</p>
          {/* <button className="card-button">Learn More</button> */}
        </div>
      </div>
    </Card>
  </Space>
  </Col>
  <Col span={4} xs={24} md={12} lg={4}>
  <Space direction="vertical" size={16}>
    <Card title={<Title level={3} >Managemant of Gardens and <br/> Surrounding</Title>} >
    <div>
        <img src="garden.jpeg" alt="Avatar" width={260} height={225} />
        <div className="card-container"> 
          <p>Managing gardens in a community involves overseeing the maintenance and development of green spaces within
            the community, while also engaging and involving community members in the gardening process,Environment
            sustainbility is focused majorly</p>
        </div>
        {/* <button className="card-button">Learn More</button> */}
      </div>
    </Card>
  </Space>
  </Col>
  <Col span={4} xs={24} md={12} lg={4}>
  <Space direction="vertical" size={16}>
    <Card title={<Title level={3}>Management of Visitor</Title>} >
    <div>
        <img src="visitors.jpeg" alt="Avatar" width={260} height={225} />
        <div className="card-container">
          <p>Managing visitors in a community involves ensuring that visitors are welcomed, informed, and engaged, while
            also ensuring that they respect the community's customs, values, and rules, </p>
          {/* <button className="card-button">Learn More</button> */}
        </div>
      </div>
    </Card>
  </Space>
  </Col>
  <Col span={5} xs={24} md={12} lg={5}>
  <Space direction="vertical" size={16}>
    <Card title={<Title level={3} className='ant-comment'>Management of Resident</Title>} >
    <div>
        <img src="Residents.jpeg" alt="Avatar"  width={260} height={225}/>
        <div className="card-container">
          <h3><b>Management of Resident</b></h3>
          <p>Managing residents in a community involves overseeing the day-to-day operations of the community, while also
            promoting a safe, healthy, and inclusive living environment for all residents.</p>
          {/* <button className="card-button">Learn More</button>  */}
        </div>
      </div>
    </Card>
  </Space>
  </Col>
  </Row>
    </>
  )
}
