import React from 'react'
import Header from './header'
import { Card, Space, Typography,Row, Col } from 'antd';

export default function About() {
  return (
    <>
    <Header headerContent = {<div className="content">
                <h1>About</h1>
            </div>} />
    <Row className="about-section">
        <Typography.Text className="para">Terrazas de Guacuco is a gated community, which provides security and privacy to its residents.
            The complex offers a range of amenities, including a swimming pool, tennis courts, basketball courts, and a
            clubhouse. The clubhouse features a restaurant and a bar, making it a great place for socializing with
            neighbors and friends.

            <br />
            The residential units in Terrazas de Guacuco are spacious and well-designed, with a modern and elegant
            architectural style. The units come in different sizes, from one to three bedrooms, and feature amenities
            such as air conditioning, Wi-Fi, and cable TV. Some units have balconies or terraces, which offer stunning
            views of the sea and the surrounding landscape.
            <br />
            Terrazas de Guacuco is also conveniently located near the commercial area of Pampatar, where residents can
            find a variety of shops, restaurants, and other services. The area is popular with tourists, and there are
            many tourist attractions nearby, including the La Restinga National Park, the Castillo San Carlos de
            Borromeo, and the beautiful beaches of Playa El Agua and Playa Parguito.
            <br />
            <br />
            Overall, Terrazas de Guacuco offers a great living environment for those who seek a combination of natural
            beauty, luxury, and convenience.
        </Typography.Text>
    </Row>
    </>
  )
}
