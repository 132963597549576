import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import axios from '../axiosConfig';

import { Layout, Typography } from 'antd';
const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

function ResidentMembershipPage() {
  return (
    <Layout>
      <Header>
        <Title level={2} style={{ color: '#fff' }}>Resident Membership</Title>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
          <Title level={4}>Welcome to the Resident Membership Page!</Title>
          <Paragraph>
            Membership to our resident community is open to all residents living on campus. By becoming a member, you will have access to a range of exclusive benefits and resources, including:
          </Paragraph>
          <ul>
            <li>Access to our on-campus facilities such as the pool, tennis court, gym, and garden</li>
            <li>Discounts on various services offered on campus</li>
            <li>A network of fellow residents to connect and collaborate with</li>
          </ul>
          <Paragraph>
            To become a member, simply visit our membership desk located in the main lobby of your residence hall. Our friendly staff will be happy to assist you with the registration process and answer any questions you may have.
          </Paragraph>
          <Paragraph>
            If you wish to cancel your membership, you can do so at any time by visiting our membership desk or contacting our office directly. We will be sorry to see you go, but we understand that circumstances can change.
          </Paragraph>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
      <Title level={2}>Join Now</Title>
      </Footer>
    </Layout>
  );
}


const MembershipsTable = () => {
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    axios.get('/api/memberships')
      .then(response => {
        setMemberships(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleJoinLeave = (id, status) => {
    const newStatus = !status;
    axios.patch(`/api/memberships/${id}/join-leave`, { status: newStatus })
      .then(response => {
        const updatedMembership = response.data.data;
        const updatedMemberships = memberships.map(m => {
          if (m.id === updatedMembership.id) {
            return updatedMembership;
          }
          return m;
        });
        setMemberships(updatedMemberships);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: 'Resident ID',
      dataIndex: 'resident_id',
      key: 'resident_id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Button onClick={() => handleJoinLeave(record.id, status)}>
          {status ? 'Leave' : 'Join'}
        </Button>
      ),
    },
  ];

  return (
    <>
    <ResidentMembershipPage/>
    <Table dataSource={memberships} columns={columns} />
    </>);
};

export default MembershipsTable;
