import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const SecurityReport = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('https://backend.pxg6404.uta.cloud/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
  
  // Add sheets to workbook

  const visitors_access = XLSX.utils.json_to_sheet(data.visitors_access);
  const security_personnel = XLSX.utils.json_to_sheet(data.security_personnel);
  const contact_form = XLSX.utils.json_to_sheet(data.contact_form);
  


  XLSX.utils.book_append_sheet(wb, visitors_access, "Visitors Access");
  XLSX.utils.book_append_sheet(wb, security_personnel, "Security Personnel");
  XLSX.utils.book_append_sheet(wb, contact_form, "Contact Form");
 

    // Export workbook
    XLSX.writeFile(wb, "SecurityReport.xlsx");
  }
  
  return (
    data ? (
      <Button type="primary" icon={<DownloadOutlined />} onClick={exportExcel}>
        Download Security Report
      </Button>
    ) : (
      <div>Loading...</div>
    )
  );
};

export default SecurityReport;
