import React from "react";
import { Tabs } from "antd";
import AdminHeader from './admin-header'
import VehicleDashboard from "./vehicles";
import Memberships from "./memberships";
import Events from "./events";
import VisitorInfoPage from "./visitor_personalinfo";
import MyMap from "./map";
import Pool from "./pool";
import EventCalendar from "./EventCalendar";

const { TabPane } = Tabs;

const Vehicles = () => {
  return (
    <div>
      <h2>Vehicles Details</h2>
      <VehicleDashboard/>
    </div>
  );
};


const AccessTimings = () => {
  return (
    <div>
      <h2>Access Timings</h2>
      <Pool/>
    </div>
  );
};

const Visitor = () => {
  return (
    <div>
 <div>
      <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Welcome !!! Visitor</h1>
            </div>
        </section>}/>

    </div>
      <Tabs defaultActiveKey="1" vertical>
        <TabPane tab="Personal Information" key="1">
          <VisitorInfoPage />  
        </TabPane>
        {/* <TabPane tab="Map" key="2">
          <MyMap/>
        </TabPane> */}
        <TabPane tab="Events" key="3">
          <Events />
        </TabPane>
        <TabPane tab="Timings" key="4">
          <AccessTimings />
        </TabPane>
        <TabPane tab="Events Calender" key="5">
          <EventCalendar />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Visitor;