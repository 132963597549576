import React, { useState, useEffect } from 'react';
import { Table, Button,message } from 'antd';
import axios from '../axiosConfig';



const EventList = () => {
    const user = JSON.parse(sessionStorage.getItem('user'))
  const [events, setEvents] = useState([]);
  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    axios.get(`/api/events/residents/${user.id}`)
      .then(res => {
        setEvents(res.data.events);
        setFetch(false)
      })
      .catch(err => {
        console.log(err);
      });
  }, [fetch, user.id]);
 

  const handleRegister = (eventId) => {
    const userId = user.id; // replace with the logged in user's ID
    axios.post(`/api/events/${eventId}/register/${userId}`)
      .then(res => {
        if(res.data.message === 'User is already registered for this event'){
            message.error("User is already registered for this event.")
        }else{
            message.success("Registration successfully")
        }
        setFetch(true)
        
      })
      .catch(err => {

        console.log(err);
      });
  };

  const handleUnregister = (eventId) => {
    const userId =user.id; // replace with the logged in user's ID
    axios.put(`/api/events/${eventId}/register/${userId}`)
      .then(res => {
        setFetch(true)
      })
      .catch(err => {
        console.log(err);
      });
  };


  const columns = [
    {
      title: 'Event Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        console.log('record',record)
       return <span>
            {record.register_status === 1 ? <Button onClick={() => handleUnregister(record.id)}>Unregister</Button> : <Button onClick={() => handleRegister(record.id)}>Register</Button> }
        </span>
      },
    },
  ];

  return (
    events.length !== 0 && <Table
      dataSource={events}
      columns={columns}
      rowKey="id"
    />
  );
};

export default EventList;
