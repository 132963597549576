import { Col, Row,Typography } from 'antd';
import React, { useState } from 'react';

const VerticalTab = ({ tabs,setHeaderName }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if(setHeaderName){
        setHeaderName(tabs[index].label)
    }
  };

  return (
    <Row className="vertical-tabs">
      <Col span={4} className="tab-list">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            <Typography.Title level={5}>
            {tab.label}
            </Typography.Title>
          </div>
        ))}
      </Col>
      
      <Col span={19} className="tab-content">
        {tabs[activeTab].content}
      </Col>
    </Row>
  );
};

export default VerticalTab;