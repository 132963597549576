import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, DatePicker, message } from "antd";
import axios from "../axiosConfig";
import moment from 'moment'
const Advertisement = () => {
  const userItem = JSON.parse(sessionStorage.getItem("user"));
  const poolManagerId = userItem.id;
  const [advertisements, setAdvertisements] = useState([]);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);

  useEffect(() => {
    getAdvertisements();
  }, []);

  const getAdvertisements = async () => {
    try {
      const response = await axios.get(
        `/api/garden-managers/${poolManagerId}/advertisements`
      );
      setAdvertisements(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (record) => {
    setSelectedAdvertisement(record);
    const formattedDate = moment(record.advertised_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
form.setFieldsValue({
  id: record.id,
  event_name: record.event_name,
  event_description: record.event_description,
  advertised_at: moment(formattedDate),
});

    setModalVisible(true);
  };

  const saveAdvertisement = async (values) => {
    try {
      let response;
      if (values.id) {
        response = await axios.put(
          `/api/garden-managers/${poolManagerId}/advertisements/${values.id}`,
          {
            event_name: values.event_name,
            event_description: values.event_description,
            advertised_at: values.advertised_at.format("YYYY-MM-DD HH:mm:ss"),
          }
        );
        getAdvertisements();
        message.success("Advertisement updated successfully");
      } else {
        response = await axios.post(
          `/api/garden-managers/${poolManagerId}/advertisements`,
          {
            event_name: values.event_name,
            event_description: values.event_description,
            advertised_at: values.advertised_at.format("YYYY-MM-DD HH:mm:ss"),
          }
        );
        message.success("Advertisement created successfully");
      }
      setAdvertisements(
        advertisements.map((ad) =>
          ad.id === response.data.id ? response.data : ad
        )
      );
      setModalVisible(false);
      setSelectedAdvertisement(null);
    } catch (error) {
      console.log(error);
      message.error("Failed to save advertisement");
    }
  };

  const deleteAdvertisement = async (id) => {
    try {
      await axios.delete(
        `/api/garden-managers/${poolManagerId}/advertisements/${id}`
      );
      message.success("Advertisement deleted successfully");
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error("Failed to delete advertisement");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Event Description",
      dataIndex: "event_description",
      key: "event_description",
    },
    {
      title: "Advertised At",
      dataIndex: "advertised_at",
      key: "advertised_at",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button danger onClick={() => deleteAdvertisement(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Create Advertisement
      </Button>
      <Table dataSource={advertisements} columns={columns} rowKey="id" />
      <Modal
        visible={modalVisible}
        title={
          selectedAdvertisement ? "Edit Advertisement" : "Create Advertisement"
        }
        okText={selectedAdvertisement ? "Update" : "Create"}
        cancelText="Cancel"
        onCancel={() => {
          setModalVisible(false);
          setSelectedAdvertisement(null);
        }}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            saveAdvertisement(values);
          });
        }}
      >
        <Form form={form}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="event_name"
            label="Event Name"
            rules={[
              {
                required: true,
                message: "Please input the event name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="event_description"
            label="Event Description"
            rules={[
              {
                required: true,
                message: "Please input the event description",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="advertised_at"
            label="Advertised At"
            rules={[
              {
                required: true,
                message: "Please select the advertised at date and time",
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Advertisement;
