import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message } from 'antd';
import axios from '../axiosConfig';
import SecurityPersonnelForm from './SecurityPersonnelForm';

const SecurityPersonnel = () => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const uniqueId = user.id
  const [securityPersonnelList, setSecurityPersonnelList] = useState([]);
  const [selectedSecurityPersonnel, setSelectedSecurityPersonnel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    axios.get('/api/security-personnel')
      .then(response => setSecurityPersonnelList(response.data))
      .catch(error => console.log(error));
  }, []);

  const handleCreate = values => {

    // axios.post('/api/security-personnel', {...values,security_manager_id:uniqueId})
    //   .then(response => {
        // setSecurityPersonnelList([...securityPersonnelList, response.data]);
    //     message.success('Security Personnel created successfully!');
        setIsModalVisible(false);
        axios.get('/api/security-personnel')
        .then(response => setSecurityPersonnelList(response.data))
        .catch(error => console.log(error));
    //   })
    //   .catch(error => console.log(error));
  };

  const handleUpdate = values => {
    // axios.put(`/api/security-personnel/${selectedSecurityPersonnel.id}`, values)
    //   .then(response => {
        // setSecurityPersonnelList(securityPersonnelList.map(sp => sp.id === response.data.id ? response.data : sp));
        // message.success('Security Personnel updated successfully!');
        setIsModalVisible(false);
        axios.get('/api/security-personnel')
        .then(response => setSecurityPersonnelList(response.data))
        .catch(error => console.log(error));
    //   })
    //   .catch(error => console.log(error));
  };

  const handleDelete = id => {
    axios.delete(`/api/security-personnel/${id}`)
      .then(() => {
        setSecurityPersonnelList(securityPersonnelList.filter(sp => sp.id !== id));
        message.success('Security Personnel deleted successfully!');
      })
      .catch(error => console.log(error));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: active => active ? 'Yes' : 'No'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => {
            setSelectedSecurityPersonnel(record);
            setIsModalVisible(true);
          }}>Edit</Button>
          <Button type="danger" onClick={() => handleDelete(record.id)}>Delete</Button>
        </>
      )
    }
  ];

  return (
    <div>
      <h1>Security Personnel</h1>
      <Button type="primary" onClick={() => {
        setSelectedSecurityPersonnel(null);
        setIsModalVisible(true);
      }}>Create Security Personnel</Button>
      <Table dataSource={securityPersonnelList} columns={columns} rowKey="id" />

      <Modal
        title={selectedSecurityPersonnel ? 'Edit Security Personnel' : 'Create Security Personnel'}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <SecurityPersonnelForm
          initialValues={selectedSecurityPersonnel}
        onFinish={selectedSecurityPersonnel ? handleUpdate : handleCreate}
        />
      </Modal>
    </div>
  );
};

export default SecurityPersonnel;
