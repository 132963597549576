import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, message, Modal, Button, Menu, Dropdown,Avatar  } from "antd";
import axios from "axios";
import ChatbotComponent from "./chatbot";
import ChatRoom from "./chatroom";
import { MessageOutlined, UserOutlined, DownOutlined } from "@ant-design/icons";
import UpdateProfileModal from "./UpdateProfileModal";

export default function AdminHeader(props) {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [isChatRoomOpen, setIsChatRoomOpen] = useState(false);
  const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(
    false
  );

  const handleLogout = async () => {
    try {
      await axios.post("https://backend.pxg6404.uta.cloud/api/logout");

      sessionStorage.removeItem("user");
      message.success("Logout successfully");
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const handleChatRoomOpen = () => {
    setIsChatRoomOpen(true);
  };

  const handleChatRoomClose = () => {
    setIsChatRoomOpen(false);
  };

  const handleUpdateProfileOpen = () => {
    setIsUpdateProfileModalOpen(true);
  };

  const handleUpdateProfileClose = () => {
    setIsUpdateProfileModalOpen(false);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleUpdateProfileOpen}>
        <UserOutlined /> Update Profile
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>
        <Button type="link">Logout</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <header className="header-bg">
        <div className="chatbot-container">
          <ChatbotComponent />
        </div>
        <Row justify="space-between" align="middle">
          <Col span={4} xs={10} md={10} lg={10}>
            <Link to="/" className="logo">
              <h3>Terrazas de Guacuco</h3>
            </Link>
          </Col>
          <Col span={6} xs={14} md={14} lg={14}>
            <div className="toggleMenu" onclick="toggleMenu();"></div>
            <Row justify="space-between" align="middle">
              <Col span={18} xs={18} md={18} lg={18} align="left">
                <ul className="primary-list row">
                  <h1>
                    Hello {user?.role !== null ? user?.role : ""},{" "}
                    {user?.username !== null ? user?.username : ""} !!!
                  </h1>
                </ul>
              </Col>
              <Col
                span={6}
                xs={24}
                md={6}
                lg={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Dropdown overlay={menu}>
                <Button style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
                    <Avatar
                      style={{ backgroundColor: "#f56a00", marginRight: "8px" }}
                      icon={<UserOutlined />}
                    />
                    <span style={{ fontSize: "16px" }}>{user?.username}</span>
                    <DownOutlined style={{ marginLeft: "8px" }} />
                  </Button>
                </Dropdown>
                <Button
                  type="primary"
                  size="large"
                  style={{ background: "#00000", borderColor: "#e6f7ff" }}
                  onClick={handleChatRoomOpen}
                >
                  <MessageOutlined /> Chat Room
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </header>
      <Row className="container hero row">{props.headerContent}</Row>
      <Modal
        width={1000}
        title="Chat Room"
        visible={isChatRoomOpen}
        onCancel={handleChatRoomClose}
        footer={null}
        destroyOnClose
      >
        <ChatRoom />
      </Modal>
      <UpdateProfileModal
        isOpen={isUpdateProfileModalOpen}
        onClose={handleUpdateProfileClose}
      />
    </div>
  );
}
