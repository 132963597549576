import React, { useEffect, useState } from "react";
import { Table } from "antd";

const AllManagers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://backend.pxg6404.uta.cloud/api/data")
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  const poolColumns = [
    {
      title: "Pool Manager ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
    },
  ];

  const gardenColumns = [
    {
      title: "Garden Manager ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
    },
  ];
  const securityColumns = [
    {
      title: "Security Manger ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
    },
  ];

  return (
    data && <>
      <h1>Pool Managers</h1>
      <Table dataSource={data?.users?.filter(item => item.role === 'Pool Manager')} columns={poolColumns} />
      <h1>Garden Managers</h1>
      <Table dataSource={data?.users?.filter(item => item.role === 'Garden Manager')} columns={gardenColumns} />
      <h1>Security Managers</h1>
      <Table dataSource={data?.users?.filter(item => item.role === 'Security Manager')} columns={securityColumns} />
    </> 
    
  );
};

export default AllManagers;
