import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const Report = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('https://backend.pxg6404.uta.cloud/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
  
  // Add sheets to workbook
  const advertisements = XLSX.utils.json_to_sheet(data.advertisements);
  const events = XLSX.utils.json_to_sheet(data.events);
  const memberships = XLSX.utils.json_to_sheet(data.memberships);
  const pool_timings = XLSX.utils.json_to_sheet(data.pool_timings);
  const residents_access = XLSX.utils.json_to_sheet(data.residents_access);
  const users = XLSX.utils.json_to_sheet(data.users);
  const vehicles = XLSX.utils.json_to_sheet(data.vehicles);
  const visitors_access = XLSX.utils.json_to_sheet(data.visitors_access);
  const security_personnel = XLSX.utils.json_to_sheet(data.security_personnel);
  const contact_form = XLSX.utils.json_to_sheet(data.contact_form);
  const garden_visitors_access = XLSX.utils.json_to_sheet(data.garden_visitors_access);
  const garden_timings = XLSX.utils.json_to_sheet(data.garden_timings);
  const garden_advertisements = XLSX.utils.json_to_sheet(data.garden_advertisements);
  const garden_residents_access = XLSX.utils.json_to_sheet(data.garden_residents_access);

  XLSX.utils.book_append_sheet(wb, advertisements, "Advertisements");
  XLSX.utils.book_append_sheet(wb, events, "Events");
  XLSX.utils.book_append_sheet(wb, memberships, "Memberships");
  XLSX.utils.book_append_sheet(wb, pool_timings, "Pool Timings");
  XLSX.utils.book_append_sheet(wb, residents_access, "Residents Access");
  XLSX.utils.book_append_sheet(wb, users, "Users");
  XLSX.utils.book_append_sheet(wb, vehicles, "Vehicles");
  XLSX.utils.book_append_sheet(wb, visitors_access, "Visitors Access");
  XLSX.utils.book_append_sheet(wb, security_personnel, "Security Personnel");
  XLSX.utils.book_append_sheet(wb, contact_form, "Contact Form");
  XLSX.utils.book_append_sheet(wb, garden_visitors_access, "Garden Visitors Access");
  XLSX.utils.book_append_sheet(wb, garden_timings, "Garden Timings");
  XLSX.utils.book_append_sheet(wb, garden_advertisements, "Garden Advertisements");
  XLSX.utils.book_append_sheet(wb, garden_residents_access, "Garden Residents Access");

    // Export workbook
    XLSX.writeFile(wb, "Report.xlsx");
  }
  
  return (
    data ? (
      <Button type="primary" icon={<DownloadOutlined />} onClick={exportExcel}>
        Download Report
      </Button>
    ) : (
      <div>Loading...</div>
    )
  );
};

export default Report;
