import React, { useState } from 'react'
import AdminHeader from './admin-header'
import { Router, Route, Link } from 'react-router-dom';
import Security from '../components/security';
import VerticalTab from '../components/vertical-tab';
const tabs = [
    {
      label: 'Manager Building',
      content:  <>
      <div className="manger-building-section">
                  {/* <ol start={1}>
      <li>
        <b><i>
            Join/Leave membership</i></b>
      </li>
    </ol>
    <ul>
      <li><Link path="/manage-building/security">Manage Security Managers</Link></li>
      <li><a href="./PoolManager.html">Manage Pool Managers</a></li>
      <li><a href="./Garden.html">Manage Garden Managers</a></li>
      <li><a href="#">Reports</a></li>
    </ul> */}

              <div className="ManagerBuildingTables">
                  <div className="tables">
                      <h1 className="table-heading">Manage Buildings</h1>
                      <table id="manageBulding">
                          <tbody><tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Community</th>
                              <th>Floors</th>
                              <th>Occupancy</th>
                          </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Rock</td>
                                  <td>RMZ</td>
                                  <td>40</td>
                                  <td>50%</td>
                              </tr>
                              <tr>
                                  <td>2</td>
                                  <td>Water</td>
                                  <td>brigade</td>
                                  <td>30</td>
                                  <td>30%</td>
                              </tr>
                              <tr>
                                  <td>3</td>
                                  <td>Forest</td>
                                  <td>Sobha</td>
                                  <td>100</td>
                                  <td>80%</td>
                              </tr>
                              <tr>
                                  <td>4</td>
                                  <td>Lake</td>
                                  <td>RMZ</td>
                                  <td>35</td>
                                  <td>100%</td>
                              </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Hill</td>
                                  <td>Sobha</td>
                                  <td>200</td>
                                  <td>20%</td>
                              </tr>
                          </tbody></table>
                      <button className="table-button">See More</button>
                  </div>
                  <div className="tables">
                      <h1 className="table-heading">Manage Buildings</h1>
                      <table id="manageBulding">
                          <tbody><tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Community</th>
                              <th>Floors</th>
                              <th>Occupancy</th>
                          </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Rock</td>
                                  <td>RMZ</td>
                                  <td>40</td>
                                  <td>50%</td>
                              </tr>
                              <tr>
                                  <td>2</td>
                                  <td>Water</td>
                                  <td>brigade</td>
                                  <td>30</td>
                                  <td>30%</td>
                              </tr>
                              <tr>
                                  <td>3</td>
                                  <td>Forest</td>
                                  <td>Sobha</td>
                                  <td>100</td>
                                  <td>80%</td>
                              </tr>
                              <tr>
                                  <td>4</td>
                                  <td>Lake</td>
                                  <td>RMZ</td>
                                  <td>35</td>
                                  <td>100%</td>
                              </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Hill</td>
                                  <td>Sobha</td>
                                  <td>200</td>
                                  <td>20%</td>
                              </tr>
                          </tbody></table>
                      <button className="table-button">See More</button>
                  </div>
                  <div className="tables">
                      <h1 className="table-heading">Pool CRUD</h1>
                      <form className="form">
                          <input className="input-content" type="text" pattern="[a-z]{1,15}" name="firstname" placeholder="First name.." />
                          <input className="input-content" type="text" pattern="[a-z]{1,15}" name="lastname" placeholder="Last name.." />
                          <select className="input-content" name="select">
                              <option value="Item1">Item1</option>
                              <option value="Item2">Item2</option>
                              <option value="Item3">Item3</option>
                          </select>
                          <textarea className="input-content" name="subject" placeholder="Write something.." style={{ height: '200px' }} defaultValue={""} />
                          <button className="submit-button">See More</button>
                      </form>
                  </div>
                  <div className="tables">
                      <h1 className="table-heading">Manage Buildings</h1>
                      <table id="manageBulding">
                          <tbody><tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Community</th>
                              <th>Floors</th>
                              <th>Occupancy</th>
                          </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Rock</td>
                                  <td>RMZ</td>
                                  <td>40</td>
                                  <td>50%</td>
                              </tr>
                              <tr>
                                  <td>2</td>
                                  <td>Water</td>
                                  <td>brigade</td>
                                  <td>30</td>
                                  <td>30%</td>
                              </tr>
                              <tr>
                                  <td>3</td>
                                  <td>Forest</td>
                                  <td>Sobha</td>
                                  <td>100</td>
                                  <td>80%</td>
                              </tr>
                              <tr>
                                  <td>4</td>
                                  <td>Lake</td>
                                  <td>RMZ</td>
                                  <td>35</td>
                                  <td>100%</td>
                              </tr>
                              <tr>
                                  <td>1</td>
                                  <td>Hill</td>
                                  <td>Sobha</td>
                                  <td>200</td>
                                  <td>20%</td>
                              </tr>
                          </tbody></table>
                      <button className="table-button">See More</button>
                  </div>
              </div>
          </div></>,
    },
    {
      label: 'Manage Security Managers',
      content: <Security />,
    },
    {
      label: 'Manage Pool Managers',
      content: <div className="manger-building-section">
      <div className="ManagerBuildingTables">
        <div className="tables">
          <h1 className="table-heading">Manage Vistors</h1>
          <table id="manageBulding">
            <tbody><tr>
                <th>S.No</th>
                <th>Name</th>
                <th>In-time</th>
                <th>Out-time</th>
                <th>Flat No</th>
                <th>Mobile Number</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Rock</td>
                <td>7:30am</td>
                <td>8:00pm</td>
                <td>102</td>
                <td>628-584-9878</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Water</td>
                <td>7:30am</td>
                <td>8:00pm</td>
                <td>105</td>
                <td>628-584-9877</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Forest</td>
                <td>7:30am</td>
                <td>8:00pm</td>
                <td>105</td>
                <td>469-584-9876</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Lake</td>
                <td>7:30am</td>
                <td>8:00pm</td>
                <td>107</td>
                <td>628-874-9875</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Hill</td>
                <td>7:30am</td>
                <td>8:00pm</td>
                <td>106</td>
                <td>852-584-9874</td>
              </tr>
            </tbody></table>
          <button className="table-button">See More</button>
        </div>
        <div className="tables">
          <h1 className="table-heading">Manage Pool Timings </h1>
          <table id="manageBulding">
            <tbody><tr>
                <th>s No</th>
                <th>Members</th>
                <th>In-time</th>
                <th>out-time</th>
              </tr>
              <tr>
                <td>1</td>
                <td>flat-104</td>
                <td>8:00am</td>
                <td>9:00am</td>
              </tr>
              <tr>
                <td>2</td>
                <td>flat-105</td>
                <td>8:00am</td>
                <td>9:00am</td>
              </tr>
              <tr>
                <td>3</td>
                <td>flat-209</td>
                <td>11:00am</td>
                <td>12:00pm</td>
              </tr>
              <tr>
                <td>4</td>
                <td>flat-304</td>
                <td>8:00am</td>
                <td>9:00am</td>
              </tr>
              <tr>
                <td>4</td>
                <td>maintance</td>
                <td>8:00am</td>
                <td>12:00pm</td>
              </tr>
            </tbody></table>
        </div>
        <div className="tables">
          <h1 className="table-heading">Manage Residents</h1>
          <table id="manageBulding">
            <tbody><tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Community</th>
                <th>Flat No</th>
                <th>members</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Rock</td>
                <td>RMZ</td>
                <td>140</td>
                <td>5</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Water</td>
                <td>brigade</td>
                <td>310</td>
                <td>3</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Forest</td>
                <td>Sobha</td>
                <td>100</td>
                <td>8</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Lake</td>
                <td>RMZ</td>
                <td>325</td>
                <td>1</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Hill</td>
                <td>Sobha</td>
                <td>201</td>
                <td>5</td>
              </tr>
            </tbody></table>
          <button className="table-button">See More</button>
        </div>
        <div className="tables">
          <h1 className="table-heading">Chat</h1>
          <form className="form">
            <input className="input-content" type="text" name="firstname" placeholder="First name.." />
            <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
            <input className="input-content" type="text" name="roomno" placeholder="Room No" />
            <select className="input-content" name="select">
              <option value="Item1">Issues</option>
              <option value="Item1">In house repairs</option>
              <option value="Item2">lease extenstion</option>
              <option value="Item3">others</option>
            </select>
            <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
            <button className="submit-button">submit</button>
          </form>
        </div>
      </div>
    </div>,
    },
    {
        label:'Garden Managers',
        content: <div className="manger-building-section">
        <div className="ManagerBuildingTables">
          <div className="tables">
            <h1 className="table-heading">Manage Vistors</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>In-time</th>
                  <th>Out-time</th>
                  <th>Flat No</th>
                  <th>Mobile Number</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Rock</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>102</td>
                  <td>628-584-9878</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Water</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>105</td>
                  <td>628-584-9877</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Forest</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>105</td>
                  <td>469-584-9876</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Lake</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>107</td>
                  <td>628-874-9875</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Hill</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>106</td>
                  <td>852-584-9874</td>
                </tr>
              </tbody></table>
            <button className="table-button">See More</button>
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Garden Timings </h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>s No</th>
                  <th>Members</th>
                  <th>In-time</th>
                  <th>out-time</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>flat-104</td>
                  <td>8:00am</td>
                  <td>9:00am</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>flat-105</td>
                  <td>8:00am</td>
                  <td>9:00am</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>flat-209</td>
                  <td>11:00am</td>
                  <td>12:00pm</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>flat-304</td>
                  <td>8:00am</td>
                  <td>9:00am</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>maintance</td>
                  <td>8:00am</td>
                  <td>12:00pm</td>
                </tr>
              </tbody></table>
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Residents</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Community</th>
                  <th>Flat No</th>
                  <th>members</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Rock</td>
                  <td>RMZ</td>
                  <td>140</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Water</td>
                  <td>brigade</td>
                  <td>310</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Forest</td>
                  <td>Sobha</td>
                  <td>100</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Lake</td>
                  <td>RMZ</td>
                  <td>325</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Hill</td>
                  <td>Sobha</td>
                  <td>201</td>
                  <td>5</td>
                </tr>
              </tbody></table>
            <button className="table-button">See More</button>
          </div>
          <div className="tables">
            <h1 className="table-heading">Chat</h1>
            <form className="form">
              <input className="input-content" type="text" name="firstname" placeholder="First name.." />
              <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
              <input className="input-content" type="text" name="roomno" placeholder="Room No" />
              <select className="input-content" name="select">
                <option value="Item1">Issues</option>
                <option value="Item1">In house repairs</option>
                <option value="Item2">lease extenstion</option>
                <option value="Item3">others</option>
              </select>
              <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
              <button className="submit-button">submit</button>
            </form>
          </div>
        </div>
      </div>
    }
  ];
export default function ManageBuilding() {
    const [pageHeader, setPageHeader] = useState('Manager Building');
    debugger
  return (
    <>
    <AdminHeader headerContent={<section className="container hero row">
          <div className="content">
              <h1>{pageHeader}</h1>
          </div>
      </section>}/>
    <VerticalTab tabs={tabs} setHeaderName={(name)=>setPageHeader(name)} />
      </>
  )
}
