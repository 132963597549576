import { Row,Col,Typography } from 'antd'
import React from 'react'
import AdminHeader from './admin-header'
import Dashboard from './dashboard'

export default function Admin() {
  return (
    <div>
      <AdminHeader />
      <Dashboard/>
    </div>
  )
}
