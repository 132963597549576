import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, message } from 'antd';
import axios from '../axiosConfig';

const { Option } = Select;

const VisitorAccess = () => {
    const userItem = JSON.parse(sessionStorage.getItem('user'));
    const poolManagerId = userItem.id;
  const [visitorsAccess, setVisitorsAccess] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getVisitorsAccess();
    getVisitors();
  }, []);

  const getVisitorsAccess = async () => {
    try {
      const response = await axios.get(`/api/garden-visitors-access/${poolManagerId}`);
      setVisitorsAccess(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getVisitors = async () => {
    try {
      const response = await axios.get('/api/visitors');
      setVisitors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button danger onClick={() => deleteVisitorAccess(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const createVisitorAccess = async (values) => {
    try {
      await axios.post('/api/garden-visitors-access', {
        garden_manager_id: poolManagerId,
        visitor_id: values.visitor_id,
      });
      setModalVisible(false);
      message.success('Visitor access created successfully');
      getVisitorsAccess();
    } catch (error) {
      console.log(error);
      message.error('Failed to create visitor access');
    }
  };

  const deleteVisitorAccess = async (id) => {
    try {
      await axios.delete(`/api/garden-visitors-access/${id}/${poolManagerId}`);
      message.success('Visitor access deleted successfully');
      getVisitorsAccess();
    } catch (error) {
      console.log(error);
      message.error('Failed to delete visitor access');
    }
  };

  return (
    <div>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Create Visitor Access
      </Button>
      <Table dataSource={visitorsAccess} columns={columns} rowKey="id" />
      <Modal
        visible={modalVisible}
        title="Create Visitor Access"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            createVisitorAccess(values);
          });
        }}
      >
        <Form form={form}>
          <Form.Item
            name="visitor_id"
            label="Visitor"
            rules={[{ required: true, message: 'Please select a visitor' }]}
          >
            <Select placeholder="Select a visitor">
              {visitors.map((visitor) => (
                <Option value={visitor.id} key={visitor.id}>
                  {visitor.username}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VisitorAccess;
