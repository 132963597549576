import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const PoolReport = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('https://backend.pxg6404.uta.cloud/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
  
  // Add sheets to workbook
  const advertisements = XLSX.utils.json_to_sheet(data.advertisements);
  const pool_timings = XLSX.utils.json_to_sheet(data.pool_timings);
  


  XLSX.utils.book_append_sheet(wb, advertisements, "Advertisements");
  XLSX.utils.book_append_sheet(wb, pool_timings, "Pool Timings");
 

    // Export workbook
    XLSX.writeFile(wb, "Pool Report.xlsx");
  }
  
  return (
    data ? (
      <Button type="primary" icon={<DownloadOutlined />} onClick={exportExcel}>
        Download Report
      </Button>
    ) : (
      <div>Loading...</div>
    )
  );
  
};

export default PoolReport;
