import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Select, Button } from 'antd';
import axios from '../axiosConfig';

const { Option } = Select;

const GardenTimings = () => {
    const user = JSON.parse(sessionStorage.getItem('user'))
  const [poolTimings, setPoolTimings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [form] = Form.useForm();

  const getPoolTimings = async () => {
    const response = await axios.get(`/api/garden-timings/${user.id}`);
    setPoolTimings(response.data);
  };
  const handleDelete = async (record) => {
    await axios.delete(`/api/garden-timings/${record.id}`);
    setPoolTimings(poolTimings.filter((timing) => timing.id !== record.id));
  };
  useEffect(() => {
    getPoolTimings();
  }, []);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setModalTitle('');
    form.resetFields();
  };

  const handleModalSubmit = async (values) => {
    const updatedValue = { garden_manager_id: user.id, ...values };
  
    if (modalTitle === 'Edit Garden Timing') {
      const response = await axios.put(`/api/garden-timings/${values.id}`, {
        ...updatedValue,
      });
      setPoolTimings(
        poolTimings.map((timing) =>
          timing.id === response.data.id ? response.data : timing
        )
      );
    } else {
      const response = await axios.post('/api/garden-timings', { ...updatedValue });
      setPoolTimings([...poolTimings, response.data]);
    }
  
    setIsModalVisible(false);
    setModalTitle('');
    form.resetFields();
  };
  
  const handleEdit = (record) => {
    setModalTitle('Edit Garden Timing');
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  
  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Day of Week',
      dataIndex: 'day_of_week',
      key: 'day_of_week',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record)}>
            Delete
          </Button>
        </>
      ),
    },
    
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Add Garden Timing
      </Button>
      <Table dataSource={poolTimings} columns={columns} />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleModalSubmit}>
        <Form.Item name="id" hidden>
  <Input />
</Form.Item>

          <Form.Item name="start_time" label="Start Time">
            <Input type='time' />
          </Form.Item>
          <Form.Item name="end_time" label="End Time">
            <Input type='time' />
          </Form.Item>
          <Form.Item name="day_of_week" label="Day of Week">
            <Select>
              <Option value="Monday">Monday</Option>
              <Option value="Tuesday">Tuesday</Option>
              <Option value="Wednesday">Wednesday</Option>
              <Option value="Thursday">Thursday</Option>
              <Option value="Friday">Friday</Option>
              <Option value="Saturday">Saturday</Option>
              <Option value="Sunday">Sunday</Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default GardenTimings;
