import React, { useState } from 'react';
import { Tabs } from 'antd';
import AdminHeader from './admin-header'
import PoolTimings from './pooltimings';
import ResidentsAccessG from './gardenresidentaccess';


import VisitorsAccess from './gardenvisaccess';
import Advertisements from './gardenad';
import GardenTimings from './gardentimings';
import Report from './GardenReports';

const { TabPane } = Tabs;

const GardenManager = () => {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
    <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Welcome !!! Garden Manager</h1>
            </div>
        </section>}/>
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Garden Timings" key="1">
        <GardenTimings />
      </TabPane>
      <TabPane tab="Residents Access" key="2">
        <div>
            <ResidentsAccessG />
        </div>
      </TabPane>
      <TabPane tab="Visitors Access" key="3">
        <VisitorsAccess />
      </TabPane>
      <TabPane tab="Advertisements" key="4">
        <Advertisements />
      </TabPane>
      <TabPane tab="Reports" key="5">
        <Report />
      </TabPane>
    </Tabs>
    </>
  );
};

export default GardenManager;
