import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Header from './header'
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography,message } from 'antd';
import emailjs from 'emailjs-com';

import axios from '../axiosConfig';

export default function Registration() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [dob, setDob] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const response = await axios.post('/api/register', {
          username,
          email,
          password,
          role,
          mobile_number: mobileNumber,
          dob,
        });
        console.log(response.data);
        if(response.data){
          emailjs.send("service_uk5hmm4","template_08j1xdq",{
            name: username,
            email: email,
            },'QyjLhYruDuTfG0qSO')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
            navigate('/login') 
        }
      } catch (error) {
        if(error.response.data.message === 'User with the same email or mobile number already exists'){
            message.error('User with the same email or mobile number already exists')
        }
        console.log(error);
      }
    };
    const navigate = useNavigate();
  return (
    <><Header />
    <Row className="form-container" align='middle' justify="center">
          <Col xs={24} md={24} lg={24} align='middle'>
          <Typography.Title level={3} >Register</Typography.Title>
          </Col>
          <Col xs={24} md={24} lg={24}>
          <form className="form" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          className="input-content"
          pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          className="input-content"
          value={email}
          pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          className="input-content"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="role">Role:</label>
        <select
          id="role"
          name="role"
          value={role}
          className="input-content"
          onChange={(e) => setRole(e.target.value)}
          required
        >
          <option value="">-- Please choose a role --</option>
          <option value="Resident">Resident</option>
          <option value="Pool Manager">Pool Manager</option>
          <option value="Visitor">Visitor</option>
          <option value="Security Manager">Security Manager</option>
          <option value="Garden Manager">Garden Manager</option>
          <option value="Admin">Admin</option>
        </select>
      </div>
      <div>
        <label htmlFor="mobileNumber">Mobile Number:</label>
        <input
          type="text"
          id="mobileNumber"
          name="mobileNumber"
          className="input-content"
          pattern="[0-9]{10}"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="dob">Date of Birth:</label>
        <input
          type="date"
          id="dob"
          name="dob"
          placeHolder="Date Of Birth"
          value={dob}
          className="input-content"
          onChange={(e) => setDob(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="submit-button">Register</button>
    </form>
          {/* <!-- <button className="submit-button"><a href="./Login.html">Register</a></button>  --> */}
          <Typography.Text level={3} >Already have an account? <Link to='/login'>Login</Link></Typography.Text>
          </Col>
      </Row></>
  )
}
