import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Table, Row, Col } from 'antd';
import ContactList from './contactsInfo';
import BarChart from './barchart';

const DataDashboard = () => {
  const [data, setData] = useState({ events: [], advertisements: [] });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch('https://backend.pxg6404.uta.cloud/api/data');
    const data = await response.json();
    setData(data);
  };

  const chartData = data.advertisements.map((advertisement) => {
    return {
      name: advertisement.event_name,
      advertisements: 1,
      events: 0,
    };
  });

  data.events.forEach((event) => {
    const index = chartData.findIndex((item) => item.name === event.name);
    if (index !== -1) {
      chartData[index].events += 1;
    } else {
      chartData.push({
        name: event.name,
        advertisements: 0,
        events: 1,
      });
    }
  });

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Event Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Start Time', dataIndex: 'start_time', key: 'start_time' },
    { title: 'End Time', dataIndex: 'end_time', key: 'end_time' },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <h3>Chart</h3>
          <LineChart width={550} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="advertisements" stroke="#8884d8" />
            <Line type="monotone" dataKey="events" stroke="#82ca9d" />
          </LineChart>
        </Col>
        <Col xs={24} md={12}>
          <h3>Events</h3>
          <Table dataSource={data.events} columns={columns} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <h3>Contact List Who contacted through contact Form</h3>
          <ContactList />
        </Col>
        <Col xs={24} md={12}>
          <h3>Bar chart</h3>
          <BarChart />
        </Col>
      </Row>
    </>
  );
};


export default DataDashboard;
