import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, message } from 'antd';
import axios from '../axiosConfig';

export default function ContactList() {
  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this contact?"
          onConfirm={() => handleDelete(record.id)}
        >
          <a>Delete</a>
        </Popconfirm>
      ),
    },
  ];

  const fetchData = async () => {
    const result = await axios.get('/api/contact');
    setData(result.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/contact/${id}`);
      message.success('Contact deleted successfully.');
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An unexpected error occurred. Please try again later.');
    }
  };

  return <Table columns={columns} dataSource={data} />;
}
