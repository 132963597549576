import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, message } from 'antd';
import axios from '../axiosConfig';
import moment from 'moment';

const Advertisement = () => {
  const userItem = JSON.parse(sessionStorage.getItem('user'));
  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);
  const poolManagerId = userItem.id;
  const [advertisements, setAdvertisements] = useState([]);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getAdvertisements();
  }, []);

  const getAdvertisements = async () => {
    try {
      const response = await axios.get(`/api/pool-managers/${poolManagerId}/advertisements`);
      setAdvertisements(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  
  const editAdvertisement = (advertisement) => {
    setSelectedAdvertisement(advertisement);
    form.setFieldsValue({
      id: advertisement.id,
      event_name: advertisement.event_name,
      event_description: advertisement.event_description,
      advertised_at: moment(advertisement.advertised_at, 'YYYY-MM-DD HH:mm:ss'),
    });
    setModalVisible(true);
  };
  
  
  const updateAdvertisement = async (id, values) => {
    try {
      await axios.put(`/api/pool-managers/${poolManagerId}/advertisements/${id}`, {
        event_name: values.event_name,
        event_description: values.event_description,
        advertised_at: values.advertised_at.format('YYYY-MM-DD HH:mm:ss'),
      });
      setModalVisible(false);
      message.success('Advertisement updated successfully');
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error('Failed to update advertisement');
    }
  };
  const createAdvertisement = async (values) => {
    try {
      const formData = {
        event_name: values.event_name,
        event_description: values.event_description,
        advertised_at: values.advertised_at.format('YYYY-MM-DD HH:mm:ss'),
      };
  
      if (values.id) { // if an ID is present, it's an update operation
        await axios.put(`/api/pool-managers/${poolManagerId}/advertisements/${values.id}`, formData);
        message.success('Advertisement updated successfully');
      } else { // otherwise it's a create operation
        await axios.post(`/api/pool-managers/${poolManagerId}/advertisements`, formData);
        message.success('Advertisement created successfully');
      }
      setModalVisible(false);
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error('Failed to save advertisement');
    }
  };
  

  const deleteAdvertisement = async (id) => {
    try {
      await axios.delete(`/api/pool-managers/${poolManagerId}/advertisements/${id}`);
      message.success('Advertisement deleted successfully');
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error('Failed to delete advertisement');
    }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      key: 'event_name',
    },
    {
      title: 'Event Description',
      dataIndex: 'event_description',
      key: 'event_description',
    },
    {
      title: 'Advertised At',
      dataIndex: 'advertised_at',
      key: 'advertised_at',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button onClick={() => editAdvertisement(record)}>Edit</Button>
          <Button danger onClick={() => deleteAdvertisement(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <div>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Create Advertisement
     
        </Button>
  <Table dataSource={advertisements} columns={columns} rowKey="id" />
  <Modal
    visible={modalVisible}
    title={selectedAdvertisement ? 'Edit Advertisement' : 'Create Advertisement'}
    okText={selectedAdvertisement ? 'Update' : 'Create'}
    cancelText="Cancel"
    onCancel={() => {
      setModalVisible(false);
      setSelectedAdvertisement(null);
    }}
    onOk={() => {
      form.validateFields().then((values) => {
        form.resetFields();
        if (selectedAdvertisement) {
          updateAdvertisement(selectedAdvertisement.id, values);
        } else {
          createAdvertisement(values);
        }
        setSelectedAdvertisement(null);
      });
    }}
  >
  <Form form={form}>
    <Form.Item name="id" hidden>
      <Input />
    </Form.Item>
    <Form.Item
      name="event_name"
      label="Event Name"
      rules={[
        {
          required: true,
          message: 'Please input the event name',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="event_description"
      label="Event Description"
      rules={[
        {
          required: true,
          message: 'Please input the event description',
        },
      ]}
    >
      <Input.TextArea />
    </Form.Item>
    <Form.Item
      name="advertised_at"
      label="Advertised At"
      rules={[
        {
          required: true,
          message: 'Please select the advertised at date and time',
        },
      ]}
    >
      <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
    </Form.Item>
  </Form>
</Modal>

</div>
);
};

export default Advertisement;