import React, { useState } from 'react'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
// import Post from './Post'
// import Link from './Link'

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#0f4d4a',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#0f4d4a',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
}

// all available config props
const config = {
  width: '300px',
  height: '400px',
  hideUserAvatar: true,
  placeholder: 'Type your response.',
  headerTitle: 'ChatBot',
}

const Chatbot = (props) => {
  let [showChat, setShowChat] = useState(false)

  const startChat = () => {
    setShowChat(true)
  }
  const hideChat = () => {
    setShowChat(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: showChat ? 'none' : '' }}>
        <ChatBot
          speechSynthesis={{ enable: true, lang: 'en-US' }}
          recognitionEnable={true}
          steps={[
            {
                id:'intro', 
                message:'What is your query about?', 
                trigger:'intro-user',
            },
            {
                id:'intro-user', 
                options:[
                {value:1,label:'Visitors related', trigger:'visitors'},
                {value:2,label:'Servics related', trigger:'services'},
                {value:3,label:'Incidents related', trigger:'incidents'},
                {value:4,label:'Something else', trigger:'contact'},
                ],
            },
            {
                id:'visitors', 
                message:'what to you want to know',
                trigger:'visitorOptions'
            },
            {
                id:'visitorOptions',
                options:[
                    {value:1,label:'How to add visitor', trigger:'addVisitor'},
                    {value:2,label:'Who will approve', trigger:'approveVisitor'},
                    {value:3,label:'Which details required', trigger:'visitorDetails'},
                ] ,
            },
            {
                id:'services', 
                message:'what to you want to know?',
                trigger:'servicesOptions'
            },
            {
                id:'servicesOptions',
                options:[
                    {value:1,label:'How to avail a service', trigger:'availService'},
                    {value:2,label:'How much they are charged', trigger:'serviceCharge'},
                ] ,
                trigger:'menu'
            },
            {
                id:'addVisitor', 
                message:'Click on the right menu from the header and click on Add visitor. Enter the details and submit. Do you want anymore help?', 
                trigger:'menu'
            },
            {
              id:'approveVisitor', 
              message:'Your respective building manager will approve the visitor. Do you want anymore help?', 
              trigger:'menu'
            },
            {
              id:'visitorDetails', 
              message:'Name, Date, time, email, phone number, model, vehicle, driving licence. Do you want anymore help?', 
              trigger:'menu'
            },
            {
                id:'incidents', 
                message:'If you want to report any kind of incidents, please click on top right menu and go to incidents page and fill in details.  Do you want to learn more?', 
                trigger:'menu'
            },
            {
                id:'availService', 
                message:'Click on the Service list from top right menu and choose any service of your choice. Do you want to learn more?', 
                trigger:'menu'
            },
            {
                id:'serviceCharge', 
                message:'Click on the Service list from top right menu. Service, the amount and respective tax is mentioned in the table. Do you want any other help?', 
                trigger:'menu'
            },
            {
                id:'contact', 
                message:'Please click on contact us link on the header bar. Do you want any other help?', 
                trigger:'menu'
            },
            {
                id:'menu',
                options:[
                    {value:1,label:'Yes',trigger:'intro'},
                    {value:2,label:'no',trigger:'no'}
                ]
            },
            {
                id:'no', 
                message:'Thank you. Have a nice day', 
                end:true,
            },
            ]}
          {...config}
        />
      </div>
      <div>
        {!showChat ? (
          <button className="btn" onClick={() => startChat()}>
            <i className="fa fa-minus"></i>
          </button>
        ) : (
          <button className="btn" onClick={() => hideChat()}>
            <i className="fa fa-plus"></i>
          </button>
        )}
      </div>
    </ThemeProvider>
  )
}

export default Chatbot