import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input } from "antd";
import axios from "../axiosConfig";

const ResidentInformation = () => {
  const [residents, setResidents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [userId, setUserId] = useState(null);

  const fetchResidents = async () => {
    const response = await axios.get("/api/resident-information");
    setResidents(response.data);
  };

  useEffect(() => {
    fetchResidents();
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.id) {
      setUserId(user.id);
    }
  }, []);

  const showModal = (resident) => {
    setIsModalVisible(true);
    if (resident) {
      form.setFieldsValue(resident);
    } else {
      form.resetFields();
    }
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      values.user_id = userId;
      if (values.id) {
        await axios.put(`/api/resident-information/${values.id}`, values);
      } else {
        await axios.post("/api/resident-information", values);
      }
      setIsModalVisible(false);
      fetchResidents();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/resident-information/${id}`);
      fetchResidents();
    } catch (error) {
      console.log(error);
    }
  };

  const user = JSON.parse(sessionStorage.getItem('user'));

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'User ID', dataIndex: 'user_id', key: 'user_id' },
    { title: 'Apartment Number', dataIndex: 'apartment_number', key: 'apartment_number' },
    { title: 'Emergency Contact Name', dataIndex: 'emergency_contact_name', key: 'emergency_contact_name' },
    { title: 'Emergency Contact Number', dataIndex: 'emergency_contact_number', key: 'emergency_contact_number' },
    { title: 'Vehicle Number', dataIndex: 'vehicle_number', key: 'vehicle_number' },
    { title: 'Parking Slot Number', dataIndex: 'parking_slot_number', key: 'parking_slot_number' },
    { title: 'Move In Date', dataIndex: 'move_in_date', key: 'move_in_date' },
    { title: 'Move Out Date', dataIndex: 'move_out_date', key: 'move_out_date' },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (resident) => (
        <div>
          <Button onClick={() => showModal(resident)}>Edit</Button>
          <Button danger onClick={() => handleDelete(resident.id)}>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => showModal()}>
        Add Resident
      </Button>
      <Table columns={columns} dataSource={residents} />
      <Modal
        title="Resident Information"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={onFinish}>
      <Form.Item name="id">
      <Input type="hidden" />
    </Form.Item>
    {user && user.id && (
      <Form.Item
        label="User ID"
        name="user_id"
        initialValue={userId}
        rules={[{ required: true, message: "Please enter user ID" }]}
      >
        <Input disabled />
      </Form.Item>
    )}
    <Form.Item
      label="Apartment Number"
      name="apartment_number"
      rules={[
        { required: true, message: "Please enter apartment number" },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Emergency Contact Name"
      name="emergency_contact_name"
      rules={[
        { required: true,
          message: "Please enter emergency contact name" },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Emergency Contact Number"
      name="emergency_contact_number"
      rules={[
        {
          required: true,
          message: "Please enter emergency contact number",
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Vehicle Number"
      name="vehicle_number"
      rules={[{ required: true, message: "Please enter vehicle number" }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Parking Slot Number"
      name="parking_slot_number"
      rules={[
        { required: true, message: "Please enter parking slot number" },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Move In Date"
      name="move_in_date"
      rules={[{ required: true, message: "Please enter move in date" }]}
    >
      <Input type="date" />
    </Form.Item>
    <Form.Item
      label="Move Out Date"
      name="move_out_date"
    >
      <Input type="date" />
    </Form.Item>
  </Form>
</Modal>
</div>
);
};

export default ResidentInformation;