import React, { useState } from 'react';
import { Row, Col, Typography, message } from 'antd';
import axios from '../axiosConfig';
import Header from './header';

export default function Contact() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('/api/contact/submit', {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      subject: subject,
    })
      .then(response => {
        message.success('Form submitted successfully.');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSubject('');
      })
      .catch(error => {
        console.error('Error:', error);
        message.error('Form submission failed. Please check the input fields and try again.');
      });
  }

  return (
    <>
    <Header/>
      <Row className="form-container" align={'center'}>
        <Col xs={22} md={22} lg={22} align='center'>
          <Typography.Title level={3}>Contact Form</Typography.Title>
        </Col>
        <Col xs={22} md={22} lg={22}>
          <form className="form" onSubmit={handleSubmit}>
            <label>First Name</label>
            <input className="input-content" type="text" name="firstname" minLength="3" pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
              placeholder="First name.." required value={firstName} onChange={e => setFirstName(e.target.value)} />
            <label>Last Name</label>
            <input className="input-content" type="text" name="lastname" pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
              placeholder="Last name.." required value={lastName} onChange={e => setLastName(e.target.value)} />

            <label>Email</label>
            <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
              placeholder="Email.." required value={email} onChange={e => setEmail(e.target.value)} />

            <label>Phone Number</label>
            <input className="input-content" type="tel" name="phone" pattern="[0-9]{10}" placeholder="Phone Number.."
              required value={phone} onChange={e => setPhone(e.target.value)} />

            <label>Subject</label>
            <textarea className="input-content" name="subject" placeholder="Write something.." style={{ height: '200px' }}
              required value={subject} onChange={e => setSubject(e.target.value)}></textarea>
            <button className="submit-button" type="submit">Submit</button>
          </form>
        </Col>
      </Row>
    </>
  );
}
