import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';

const BarChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('https://backend.pxg6404.uta.cloud/api/data')
      .then(response => {
        // Extract the data you need for the bar chart
        const chartData = response.data.events.map(event => ({
          name: event.name,
          date: event.start_time
        }));

        // Add barchart data from API
        const apiChartData = response.data.garden_advertisements.map(ad => ({
          name: ad.event_name,
          date: ad.advertised_at
        }));

        // Combine the barchart data from API with the existing chartData
        const finalData = [...chartData, ...apiChartData];

        setData(finalData);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <VictoryChart>
      <VictoryAxis
        dependentAxis
        tickFormat={tick => tick}
      />
      <VictoryAxis />
      <VictoryBar
        data={data}
        x="name"
        y={(d) => new Date(d.date).getTime()}
      />
    </VictoryChart>
  );
};

export default BarChart;
