import React,{useEffect} from 'react'
import '../styles/_home.scss'
import HeaderR from './header'
import { Row, Col,Layout, Typography,Button,Space, Card } from 'antd';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"

const { Header, Content, Footer } = Layout;
const { Title } = Typography;



export default function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
          if (user.role === 'Resident') {
            console.log("Navigating to /resident...", user);
            navigate('/resident');
          } else if (user.role === 'Visitor') {
            navigate('/visitor');
          } else if (user.role === 'Pool Manager') {
            navigate('/poolmanager');
          }  else if (user.role === 'Security Manager') {
            navigate('/securitymanger')
          }else {
            console.log("Navigating to /admin...", user.role);
            navigate('/admin');
          }
        }
      }, []);
    
    const user = JSON.parse(sessionStorage.getItem('user'))
    if(user && Object.keys(user).length > 0){
    if(user && user.role === 'Resident'){ 
        console.log("Navigating to /resident...",user);
        navigate('/resident')
      }else if(user.role === 'Visitor'){
        navigate('/visitor')
      }else if(user.role === 'Pool Manager'){
        navigate('/poolmanager')
      }else if(user?.role === 'Admin'){
        console.log("Navigating to /admin...",user.role);
        navigate('/admin')
      }else{
        navigate('/admin')
      }
    }

  return <><HeaderR headerContent={<div class="content">
      <h1> <marquee>Welcome to..!</marquee></h1>
      <h1><marquee direction="right" behavior="alternate">Terrazas de Guacuco</marquee></h1>
  </div>} /><Layout className="layout">
          {/* <Header>
              <div className="logo" />
          </Header> */}
          <Content style={{ padding: '0 50px' }}>
              <div className="site-layout-content">
              <img src="01.jpg" alt="My Image" />
                  <Title>Welcome to Terrazas</Title>
                  <Card>
      
    </Card>
                  <p>
                      Welcome to Terrazas, your all-in-one solution for managing your complex! Our easy-to-use
                      platform allows you to access all the information you need, from events and pool timings
                      to resident and visitor access. With our intuitive interface and powerful features, you
                      can stay on top of everything happening in your complex with just a few clicks. Plus,
                      our responsive layout ensures that you can access our platform from any device, whether
                      you're at home or on the go. So why wait? Sign up for Terrazas today and take control of
                      your complex!
                  </p>
                  <div className='button-container'>
                  <Button className="custom-large-button" type="primary">
                  
        <Link to="/registration">Login/Register</Link>
      </Button>
      </div>
              </div>

          </Content>
          <Footer style={{ textAlign: 'center', textSizeAdjust:'auto' }}> <Space size={40} >
  <Link to='/'>Home</Link>
  <Link to='/services'>Services</Link>
  <Link to='/about'>About</Link>
  <a href='#' onClick={() => window.open('http://pxc2168.uta.cloud/', '_blank', 'noopener,noreferrer')}>Blog</a>
  <Link to='/contact'>Contact</Link>
</Space></Footer>
      </Layout></>
              
}
