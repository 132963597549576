import { useState, useEffect } from 'react';
import { Table, Modal, Button, Form, Input } from 'antd';
import axios from '../axiosConfig';
// import uuid from 'uuid-js';
const VehicleDashboard = () => {
   
    const user = JSON.parse(sessionStorage.getItem('user'))
    const uniqueId = user.id
    const [vehicles, setVehicles] = useState([]);
    const [visible, setVisible] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    useEffect(() => {
        fetchVehicles();
      }, []);
    const handleEdit = (record) => {
        setVisible(true);
        form.setFieldsValue({
            resident_id: record.resident_id,
            make: record.make,
            model: record.model,
            license_plate_number: record.license_plate_number,
            id: record.id, // add this line to pass the id to the form
          });
      };
      
      const handleOk = () => {
        setVisible(false);
        form.resetFields();
      };
      
      const handleCancel = () => {
        setVisible(false);
        form.resetFields();
      };
      
      const onFinish = (values) => {
        console.log('values',values)
        axios.post('/api/vehicles', values).then(() => {
          form.resetFields();
          setCreateModalVisible(false);
          fetchVehicles();
        });
      };
      const onEditFinish = (values) => {
        console.log(values)
        axios.put(`/api/vehicles/${values.id}`, values).then(() => {
          form.resetFields();
          setVisible(false);
          fetchVehicles();
        }).catch((e)=>console.log(e));
      };
      
      const handleDelete = (id) => {
        axios.delete(`/api/vehicles/${id}`).then(() => {
          fetchVehicles();
        });
      };
      
      const fetchVehicles = () => {
        axios.get('/api/vehicles').then((response) => {      
          setVehicles(response.data.data.filter(vehicle => vehicle.resident_id === uniqueId));
        });
      };
    const [form] = Form.useForm();

    const columns = [
        {
          title: 'Resident ID',
          dataIndex: 'resident_id',
          key: 'resident_id',
        },
        {
          title: 'Make',
          dataIndex: 'make',
          key: 'make',
        },
        {
          title: 'Model',
          dataIndex: 'model',
          key: 'model',
        },
        {
          title: 'License Plate Number',
          dataIndex: 'license_plate_number',
          key: 'license_plate_number',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <span>
              <a onClick={() => handleEdit(record)}>Edit</a>
              <span> | </span>
              <a onClick={() => handleDelete(record.id)}>Delete</a>
            </span>
          ),
        },
      ];
      return (
        <div>
          <Button type="primary" onClick={() => setCreateModalVisible(true)}>
            Create Vehicle
          </Button>
          <Table dataSource={vehicles} columns={columns} />
      
          <Modal
            title="Vehicle Details"
            open={visible}
            onOk={form.submit}
            onCancel={handleCancel}
          >
            <Form form={form} onFinish={onEditFinish}>
            <Form.Item name="id" label="Resident ID" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item name="resident_id" label="Resident ID" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item name="make" label="Make">
                <Input />
              </Form.Item>
              <Form.Item name="model" label="Model">
                <Input />
              </Form.Item>
              <Form.Item
                name="license_plate_number"
                label="License Plate Number"
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
      
          <Modal
            title="Create Vehicle"
            open={createModalVisible}
            onOk={form.submit}
            onCancel={() => setCreateModalVisible(false)}
          >
            <Form form={form} onFinish={onFinish}
  initialValues={{
    resident_id: uniqueId,
  }}>
              <Form.Item name="resident_id" label="Resident ID" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item name="make" label="Make">
                <Input />
              </Form.Item>
              <Form.Item name="model" label="Model">
                <Input />
              </Form.Item>
              <Form.Item
                name="license_plate_number"
                label="License Plate Number"
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      );
 
};

export default VehicleDashboard;