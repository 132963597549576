import React, { useEffect, useState } from "react";
import { Table } from "antd";

const ResidentsAndVisitorsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://backend.pxg6404.uta.cloud/api/data")
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  const residentsColumns = [
    {
      title: "Resident ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
    },
  ];

  const visitorsColumns = [
    {
      title: "Visitor ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
    },
  ];

  return (
    data && <>
      <h1>Residents</h1>
      <Table dataSource={data?.users?.filter(item => item.role === 'Resident')} columns={residentsColumns} />
      <h1>Visitors</h1>
      <Table dataSource={data?.users?.filter(item => item.role === 'Visitor')} columns={visitorsColumns} />
    </> 
    
  );
};

export default ResidentsAndVisitorsTable;
