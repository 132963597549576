import React,{useState,useEffect} from 'react'
import { Link } from "react-router-dom"
import Header from './header'
import { Row, Col, Typography, message } from 'antd';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      if (user.role === 'Resident') {
        console.log("Navigating to /resident...", user);
        navigate('/resident');
      } else if (user.role === 'Visitor') {
        navigate('/visitor');
      } else if (user.role === 'Pool Manager') {
        navigate('/poolmanager');
      } else if (user.role === 'Garden Manager') {
        navigate('/gardenmanager');
      } else {
        console.log("Navigating to /admin...", user.role);
        navigate('/admin');
      }
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send login request to server
      const res = await axios.post('https://backend.pxg6404.uta.cloud/api/login', { email, password });
      // Save user data to session storage
      sessionStorage.setItem('user', JSON.stringify(res.data.user));
      // Redirect to dashboard page
      // window.location.href = '/dashboard';
      const user = JSON.parse(sessionStorage.getItem('user'))
      if (user?.role === 'Resident') { 
        
        console.log("Navigating to /resident...", user?.role);
        window.history.pushState(null, '', '/resident');
        navigate('/resident');
      } else if (user?.role === 'Visitor') {
        window.history.pushState(null, '', '/visitor');
        navigate('/visitor');
      } else if (user?.role === 'Pool Manager') {
        window.history.pushState(null, '', '/poolmanager');
        navigate('/poolmanager');
      }else if (user.role === 'Garden Manager') {
        navigate('/gardenmanager');
      } else if (user.role === 'Security Manager') {
        navigate('/securitymanger');
      } else {
        console.log("Navigating to /admin...",user?.role);
        window.history.pushState(null, '', '/admin');
        navigate('/admin');
      }
      
    } catch (err) {
      // Display error message to user
      setError(err.response.data.message);
      message.error(err.response.data.message)
    }
  };
 
  return (
    <><Header />
    <Row align='middle' justify="center" className="Register" >
      <Col xs={24} md={24} lg={24} align='center'>
    <Typography.Title level={3}>LogIn</Typography.Title>
    </Col>
    <Col xs={24} md={24} lg={24}>
    <form className="form" onSubmit={handleSubmit}>
        <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}" value={email} onChange={(e) => setEmail(e.target.value)}
            placeholder="Email" required />
        <input className="input-content" type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        {/* <!-- <button className="submit-button" type="submit"><a href="./Admin.html">LogIn</a></button> -->
        <!-- <input className="submit-button"type="submit"  > --> */}
        <button className="submit-button">LogIn</button>
        {error && <div className="error">{error}</div>}

    </form>
    </Col>
    <Col xs={24} md={12} lg={12} align='left'>
    <Typography.Text level={3}>Do'not have an account? <Link to='/registration'>Register</Link> <Link to='/forgotpassword'>Forget Password</Link></Typography.Text>
    </Col>
</Row>
</>
  )
}
