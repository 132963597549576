import React, { useState, useEffect } from "react";
import { Calendar, Modal } from "antd";
import axios from "axios";
import { useSpring, animated } from "react-spring";

const EventCalendar = () => {
  const [visible, setVisible] = useState(false);
  const [event, setEvent] = useState({});
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("https://backend.pxg6404.uta.cloud/api/data");
      setEvents(result.data.garden_advertisements, result.data.advertisements);
    };
    fetchData();
  }, []);

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const onSelect = (value) => {
    const event = events.find(
      (event) =>
        event.advertised_at.startsWith(value.format("YYYY-MM-DD"))
    );
    if (event) {
      setEvent(event);
      setVisible(true);
    }
  };

  const dateCellRender = (value) => {
    const event = events.find(
      (event) =>
        event.advertised_at.startsWith(value.format("YYYY-MM-DD"))
    );
    if (event) {
      return (
        <div>
          <p>{event.event_name}</p>
          <p>{event.advertised_at}</p>
        </div>
      );
    } else {
      return null; // or return a default element
    }
  };

  const modalSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.5)" },
    to: {
      opacity: visible ? 1 : 0,
      transform: visible ? "scale(1)" : "scale(0.5)",
    },
    config: { tension: 150, friction: 10 },
  });

  return (
    <>
      <Calendar
        onPanelChange={onPanelChange}
        onSelect={onSelect}
        dateCellRender={dateCellRender}
      />
      <animated.div style={modalSpring}>
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={() => setVisible(false)}
          title={event.event_name}
        >
           <p>{event.advertised_at}</p>
          <p>{event.description}</p>
        </Modal>
      </animated.div>
    </>
  );
};

export default EventCalendar;
