
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Services from './pages/services';
import About from './pages/about';
import Contact from './pages/contact';
import Registration from './pages/registration';
import Login from './pages/login';
import ForgetPassword from './pages/forget-password';
import Admin from './pages/admin';
import ManageBuilding from './pages/manage-building';
import ManageVisitor from './pages/manage-visitor';
import Resident from './pages/resident';
import Visitor from './pages/visitor';
import PoolManager from './pages/poolmanager';
import GardenManager from './pages/gardenmanager';
import SecurityManager from './pages/securitymanger';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" component={<Home />}/>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/manage-building" element={<ManageBuilding/>} />
          <Route path="/manage-visitor" element={<ManageVisitor/>} />
          <Route path="/resident" element={<Resident/>} />
          <Route path="/visitor" element={<Visitor/>} />
          <Route path="/poolmanager" element = {<PoolManager/>} />
          <Route path="/gardenmanager" element = {<GardenManager />} />
          <Route path="/securitymanger" element = {<SecurityManager />} />

          
      </Routes>
    </BrowserRouter>
  );
}

export default App;
