import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Select, Button } from 'antd';
import axios from '../axiosConfig';

const { Option } = Select;

const PoolTimings = () => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const [poolTimings, setPoolTimings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [form] = Form.useForm();

  const getPoolTimings = async () => {
    if(user){
      const response = await axios.get('/api/pool-timings', { params: { pool_manager_id: user.id }});
      setPoolTimings(response.data); 
    }
  };

  useEffect(() => {
    getPoolTimings();
  }, []);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setModalTitle('');
    form.resetFields();
  };

  const handleModalSubmit = async (values) => {
    try {
      const updatedValue = {pool_manager_id: user.id, ...values};
      if (modalTitle === 'Edit Pool Timing') {
        await axios.put(`/api/pool-timings/${form.getFieldValue('id')}`, updatedValue);
        const updatedPoolTimings = poolTimings.map(timing => {
          if (timing.id === form.getFieldValue('id')) {
            return {...timing, ...updatedValue};
          }
          return timing;
        });
        setPoolTimings(updatedPoolTimings);
      } else {
        const response = await axios.post('/api/pool-timings', {...updatedValue});
        setPoolTimings([...poolTimings, response.data]);
      }
      setIsModalVisible(false);
      setModalTitle('');
      form.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Day of Week',
      dataIndex: 'day_of_week',
      key: 'day_of_week',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button onClick={() => {
            setIsModalVisible(true);
            setModalTitle('Edit Pool Timing');
            form.setFieldsValue(record);
          }}>Edit</Button>
          <Button danger onClick={async () => {
            await axios.delete(`/api/pool-timings/${record.id}/${user.id}`);
            getPoolTimings();
          }}>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Add Pool Timing
      </Button>
      <Table dataSource={poolTimings} columns={columns} />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleModalSubmit}>
          <Form.Item name="start_time" label="Start Time">
            <Input type ='time'
      // Use 24-hour format with AM/PM selector
      addonAfter={<Select defaultValue="AM" style={{ width: 80 }} onChange={(value) => {
        const time = form.getFieldValue('start_time');
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];
        const newTime = value === 'AM' ? `${hours}:${minutes}` : `${parseInt(hours) + 12}:${minutes}`;
        form.setFieldsValue({start_time: newTime});
      }}>
        <Option value="AM">AM</Option>
        <Option value="PM">PM</Option>
      </Select>}
    />
  </Form.Item>
  <Form.Item name="end_time" label="End Time">
    <Input type='time'
      // Use 24-hour format with AM/PM selector
      addonAfter={<Select defaultValue="AM" style={{ width: 80 }} onChange={(value) => {
        const time = form.getFieldValue('end_time');
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];
        const newTime = value === 'AM' ? `${hours}:${minutes}` : `${parseInt(hours) + 12}:${minutes}`;
        form.setFieldsValue({end_time: newTime});
      }}>
        <Option value="AM">AM</Option>
        <Option value="PM">PM</Option>
      </Select>}
    />
  </Form.Item>
  <Form.Item name="day_of_week" label="Day of Week">
    <Select>
      <Option value="Monday">Monday</Option>
      <Option value="Tuesday">Tuesday</Option>
      <Option value="Wednesday">Wednesday</Option>
      <Option value="Thursday">Thursday</Option>
      <Option value="Friday">Friday</Option>
      <Option value="Saturday">Saturday</Option>
      <Option value="Sunday">Sunday</Option>
    </Select>
  </Form.Item>
  <Button type="primary" htmlType="submit">
    Submit
  </Button>
</Form>
</Modal>
</>
);
};

export default PoolTimings;