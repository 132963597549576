import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { DashboardOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import DataDashboard from './charts';
import ResidentsAndVisitorsTable from './resvis';
import ChartComponent from './reports'
import AllManagers from './allmanagers';

const { Sider, Content } = Layout;

const Board = () => {
  return (
    <div>
     <DataDashboard />
    </div>
  );
};

const ResidentsAndVisitors = () => {
  return (
    <div>
      <ResidentsAndVisitorsTable/>
    </div>
  );
};

const Buildings = () => {
  return (
    <div>
      <ChartComponent/>
      </div>
  );
};

const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState('dashboard');

  const handleMenuClick = (e) => {
    setCurrentTab(e.key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <Menu onClick={handleMenuClick} selectedKeys={[currentTab]} mode="vertical">
          <Menu.Item key="dashboard" icon={<DashboardOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}>
            <span style={{ fontSize: '18px' }}>Dashboard</span>
          </Menu.Item>
          <Menu.Item key="residentsAndVisitors" icon={<UserOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}>
            <span style={{ fontSize: '18px' }}>Residents and Visitors</span>
          </Menu.Item>
          <Menu.Item key="buildings" icon={<HomeOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}>
            <span style={{ fontSize: '18px' }}>Reports</span>
          </Menu.Item>
          <Menu.Item key="managers" icon={<UserOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}>
            <span style={{ fontSize: '18px' }}>All Managers</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: '16px', marginBottom: '20px' }}>
          {currentTab === 'dashboard' && <Board />}
          {currentTab === 'residentsAndVisitors' && <ResidentsAndVisitors />}
          {currentTab === 'buildings' && <Buildings key='sdbhbdhabhdsb'/>}
          {currentTab === 'managers' && <AllManagers />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
