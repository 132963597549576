import React, { useState } from 'react';
import { Form, Input, Button, Switch, message } from 'antd';
import axios from '../axiosConfig';

const SecurityPersonnelForm = ({ initialValues, onFinish }) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const uniqueId = user.id
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = values => {
    setLoading(true);
    const requestMethod = initialValues ? 'put' : 'post';
    const url = initialValues ? `https://backend.pxg6404.uta.cloud/api/security-personnel/${initialValues.id}` : 'https://backend.pxg6404.uta.cloud/api/security-personnel';
    axios[requestMethod](url, {...values,security_manager_id:uniqueId})
      .then(response => {
        message.success(`Security Personnel ${initialValues ? 'updated' : 'created'} successfully`);
        form.resetFields();
        onFinish(response.data);
      })
      .catch(error => {
        console.log(error);
        message.error(`Failed to ${initialValues ? 'update' : 'create'} Security Personnel`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: 'Please enter a phone number' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Active" name="active" valuePropName="checked">
        <Switch />
      </Form.Item>
      {/* <Form.Item label="Security Manager ID" name="security_manager_id" value={uniqueId}>
        <Input />
      </Form.Item> */}
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {initialValues ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SecurityPersonnelForm;
