import React from "react";

const ChatRoom = ({ onClose }) => {
  return (
    <div className="chatcord">
      <div className="chatcord-header">
      </div>
      <iframe
        src="http://localhost:7000"
        width="100%"
        height="600"
        frameBorder="0"
      />
    </div>
  );
};

export default ChatRoom;
