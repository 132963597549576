import { Row,Col,Typography,List } from 'antd'
import React from 'react'
import AdminHeader from './admin-header'

export default function ManageVisitor() {
  const data = [
    'This is the list item',
    'This is the list item',
    'This is the list item',
    'This is the list item',
    'This is the list item',
  ];
  return (
    <>
    <AdminHeader headerContent={<section className="container hero row">
          <div className="content">
              <h1>Manage Visitor</h1>
          </div>
      </section>}/>
      <Row className="manger-building-section">
        <Col span={3} xs={3} md={3} lg={3}>
        <List
        className='list-item-visitor'
        size="large"
      bordered
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
         <Typography.Text strong>{item}</Typography.Text>
        </List.Item>
      )}
    />
        </Col>
        <Col span={21} xs={21} md={21} lg={21} className="ManagerBuildingTables">
          <div className="tables">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.3059499407!2d-74.25986652425023!3d40.69714941680757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1676455037297!5m2!1sen!2sin" width={520} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Visitor</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Community</th>
                  <th>Floors</th>
                  <th>Occupancy</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Rock</td>
                  <td>RMZ</td>
                  <td>40</td>
                  <td>50%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Water</td>
                  <td>brigade</td>
                  <td>30</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Forest</td>
                  <td>Sobha</td>
                  <td>100</td>
                  <td>80%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Lake</td>
                  <td>RMZ</td>
                  <td>35</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Hill</td>
                  <td>Sobha</td>
                  <td>200</td>
                  <td>20%</td>
                </tr>
              </tbody></table>
            <button className="table-button">See More</button>
          </div>
          <div className="tables">
            <h1 className="table-heading">Calender</h1>
            <div className="month">
              <ul>
                <li className="prev">❮</li>
                <li className="next">❯</li>
                <li>
                  August<br />
                  <span style={{fontSize: '18px'}}>2021</span>
                </li>
              </ul>
            </div>
            <ul className="weekdays">
              <li>Mo</li>
              <li>Tu</li>
              <li>We</li>
              <li>Th</li>
              <li>Fr</li>
              <li>Sa</li>
              <li>Su</li>
            </ul>
            <ul className="days">
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li><span className="active">10</span></li>
              <li>11</li>
              <li>12</li>
              <li>13</li>
              <li>14</li>
              <li>15</li>
              <li>16</li>
              <li>17</li>
              <li>18</li>
              <li>19</li>
              <li>20</li>
              <li>21</li>
              <li>22</li>
              <li>23</li>
              <li>24</li>
              <li>25</li>
              <li>26</li>
              <li>27</li>
              <li>28</li>
              <li>29</li>
              <li>30</li>
              <li>31</li>
            </ul>
          </div>
          <div className="tables">
            <h1 className="table-heading">Vistors Details</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>Days</th>
                  <th>Timings</th>
                  <th>Rules</th>
                </tr>
                <tr>
                  <td>Mon-Fri</td>
                  <td>Vistors parking</td>
                  <td>Registration required</td>
                </tr>
                <tr>
                  <td>Sat-Sun</td>
                  <td>First Floor</td>
                  <td>Registration required</td>
                </tr>
              </tbody></table>
          </div>
          <div className="tables">
            <h1 className="table-heading">Register DL/Number Plate</h1>
            <form className="form">
              <input className="input-content" type="text" pattern="[a-z]{1,15}" name="firstname" placeholder="First name.." />
              <input className="input-content" type="text" pattern="[a-z]{1,15}" name="lastname" placeholder="Last name.." />
              <input className="input-content" type="text" name="VechileNo" placeholder="Vechile Number/Dl" />
              <textarea className="input-content" name="other details" placeholder="Other details.." style={{height: '200px'}} defaultValue={""} />
              <button className="submit-button">Submit</button>
            </form>
          </div>
          <div className="tables">
            <h1 className="table-heading">Chat</h1>
            <form className="form">
              <input className="input-content" type="text" name="firstname" placeholder="First name.." />
              <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
              <input className="input-content" type="text" name="roomno" placeholder="Room No" />
              <select className="input-content" name="select">
                <option value="Item1">Issues</option>
                <option value="Item1">In house repairs</option>
                <option value="Item2">lease extenstion</option>
                <option value="Item3">others</option>
              </select>
              <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
              <button className="submit-button">submit</button>
            </form>
          </div>
        </Col>
      </Row>
      </>
  )
}
