import React from 'react'

export default function Security() {
  return (
    <div className="manger-building-section">
        <div className="ManagerBuildingTables">
          <div className="tables">
            <h1 className="table-heading">Manage Vistors</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>In-time</th>
                  <th>Out-time</th>
                  <th>Flat No</th>
                  <th>Mobile Number</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Rock</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>102</td>
                  <td>628-584-9878</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Water</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>105</td>
                  <td>628-584-9877</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Forest</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>105</td>
                  <td>469-584-9876</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Lake</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>107</td>
                  <td>628-874-9875</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Hill</td>
                  <td>7:30am</td>
                  <td>8:00pm</td>
                  <td>106</td>
                  <td>852-584-9874</td>
                </tr>
              </tbody></table>
            <button className="table-button">See More</button>
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Security </h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>s No</th>
                  <th>cameras</th>
                  <th>Area</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Camera-1</td>
                  <td>floor1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Camera-2</td>
                  <td>floor2</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Camera-3</td>
                  <td>Entrance gate</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Camera-4</td>
                  <td>lift</td>
                </tr>
              </tbody></table>
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Timings</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>Days</th>
                  <th>Name</th>
                  <th>Rules</th>
                  <th>Timings</th>
                </tr>
                <tr>
                  <td>Mon-Fri</td>
                  <td>Vistors parking</td>
                  <td>Registration required</td>
                  <td>All time</td>
                </tr>
                <tr>
                  <td>Sat-Sun</td>
                  <td>Vistors parking</td>
                  <td>Registration required</td>
                  <td>All time</td>
                </tr>
                <tr>
                  <td>Sat-Sun</td>
                  <td>party room</td>
                  <td>Registration required</td>
                  <td>8pm-2am</td>
                </tr>
                <tr>
                  <td>All days</td>
                  <td>Gym room</td>
                  <td>Registration required</td>
                  <td>4am-12pm</td>
                </tr>
                <tr>
                  <td>All days</td>
                  <td>Game room</td>
                  <td>Registration required</td>
                  <td>8am-8pm</td>
                </tr>
                <tr>
                  <td>All days</td>
                  <td>study carrel</td>
                  <td>Registration required</td>
                  <td>24/7</td>
                </tr>
              </tbody></table>
          </div>
          <div className="tables">
            <h1 className="table-heading">Manage Residents</h1>
            <table id="manageBulding">
              <tbody><tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Community</th>
                  <th>Flat No</th>
                  <th>members</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Rock</td>
                  <td>RMZ</td>
                  <td>140</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Water</td>
                  <td>brigade</td>
                  <td>310</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Forest</td>
                  <td>Sobha</td>
                  <td>100</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Lake</td>
                  <td>RMZ</td>
                  <td>325</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Hill</td>
                  <td>Sobha</td>
                  <td>201</td>
                  <td>5</td>
                </tr>
              </tbody></table>
            <button className="table-button">See More</button>
          </div>
          <div className="tables">
            <h1 className="table-heading">Chat</h1>
            <form className="form">
              <input className="input-content" type="text" name="firstname" placeholder="First name.." />
              <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
              <input className="input-content" type="text" name="roomno" placeholder="Room No" />
              <select className="input-content" name="select">
                <option value="Item1">Issues</option>
                <option value="Item1">In house repairs</option>
                <option value="Item2">lease extenstion</option>
                <option value="Item3">others</option>
              </select>
              <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
              <button className="submit-button">submit</button>
            </form>
          </div>
        </div>
      </div>
  )
}
