import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
// import './Header.css';

export default function Header(props) {
  return (
    <div>
      <header className='header-bg'>
        <Row justify='space-between' align='middle'>
          <Col span={4} xs={10} md={10} lg={10}>
            <Link to='/' className='logo'>
              <h3>Terrazas de Guacuco</h3>
            </Link>
          </Col>
          <Col span={6} xs={14} md={14} lg={14}>
            <Row justify='space-between' align='middle'>
              <Col span={18} xs={18} md={18} lg={18} align='start'>
                <ul className='primary-list row'>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link to='/services'>Services</Link></li>
                  <li><Link to='/about'>About</Link></li>
                  <li><a href='#' onClick={() => window.open('http://pxc2168.uta.cloud/', '_blank', 'noopener,noreferrer')}>Blog</a></li>
                  <li><Link to='/contact'>Contact</Link></li>
                </ul>
              </Col>
              <Col span={6} xs={6} md={6} lg={6}>
                <ul className='secondary-list row'>
                  <li><Button className="loginbtn-small"><Link to='/registration'>Login/Register</Link></Button></li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </header>
      <Row className='container hero row'>
        <Col>{props.headerContent}</Col>
      </Row>
    </div>
  );
}
