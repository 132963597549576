import axios from '../axiosConfig';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import Header from './header';

export default function ForgetPassword() {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            message.error('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post('/api/user/forgetpassword', {
                email,
                new_password: newPassword
            });
            console.log(response.data);
            if (response.data.message === 'User updated successfully') {
                message.success('Password updated successfully');
                // Redirect to login page
                navigate('/login') 
            } else {
                message.error('Error occurred while updating password');
            }
        } catch (error) {
            console.log(error);
            message.error('Error occurred while updating password');
        }
    };

    return (
        <>
            <Header />
            <div className="Register">
                <h3>Forgot Password</h3>
                <form className="form" onSubmit={handleSubmit}>
                    <input
                        className="input-content"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
                        required
                    />
                    <input
                        className="input-content"
                        type="password"
                        name="new_password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <input
                        className="input-content"
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <input className="submit-button" type="submit" value="Reset Password" />
                </form>
                <h6>
                    Have an account?
                    <a href="/login">Login</a>
                </h6>
            </div>
        </>
    );
}
