import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const Report = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('https://backend.pxg6404.uta.cloud/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
  
  const garden_visitors_access = XLSX.utils.json_to_sheet(data.garden_visitors_access);
  const garden_timings = XLSX.utils.json_to_sheet(data.garden_timings);
  const garden_advertisements = XLSX.utils.json_to_sheet(data.garden_advertisements);
  const garden_residents_access = XLSX.utils.json_to_sheet(data.garden_residents_access);
  XLSX.utils.book_append_sheet(wb, garden_visitors_access, "Garden Visitors Access");
  XLSX.utils.book_append_sheet(wb, garden_timings, "Garden Timings");
  XLSX.utils.book_append_sheet(wb, garden_advertisements, "Garden Advertisements");
  XLSX.utils.book_append_sheet(wb, garden_residents_access, "Garden Residents Access");

    // Export workbook
    XLSX.writeFile(wb, "Garden Report.xlsx");
  }
  
  return (
    data ? (
        <Button type="primary" icon={<DownloadOutlined />} onClick={exportExcel}>
          Download Report
        </Button>
      ) : (
        <div>Loading...</div>
      )
  );
};

export default Report;
